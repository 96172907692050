/* global.css */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset and Base Styles */
html, body {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @apply leading-[normal] m-0 bg-background-white;
  position: relative;
}

#root {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

*,
::before,
::after {
  border-width: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Mobile Viewport Fix */
@supports (-webkit-touch-callout: none) {
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
}
